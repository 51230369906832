<template>
  <v-app id="login ">
    <v-main
      class="login-panel"
      :style="{ backgroundImage: `url(${backgroundUrl})` }"
    >
      <v-container fluid>
        <v-dialog v-model="showMessageSnackbar" max-width="400px" persistent>
          <v-card flat class="Card-style pa-2">
            <v-container fluid>
              <v-layout justify-center>
                <v-flex xs12 sm12>
                  <v-flex xs12>
                    <p>
                      <b>{{ showMessage }}</b>
                    </p>
                  </v-flex>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeMessageDialog" depressed>OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </v-dialog>
        <template>
          <v-row justify="center">
             <v-dialog v-model="showErrorTwoFactordDalog" max-width="290">
              <v-card>
                <v-card-title class="headline">
                  {{ printErrorMessageTitle }}
                </v-card-title>

                <v-card-text>
                  {{ printErrorMessage }}
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <!-- <v-btn color="green darken-1" text @click="canceldialogPopUp">
                    Cancel
                  </v-btn> -->

                  <v-btn color="green darken-1" text @click="closeDialogPopUp">
                    OK
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="showErrordialog" max-width="440px" persistent>
              <v-card flat class="Card-style pa-2">
                <v-card-title class="headline" v-if="printErrorMessageTitle">
                  {{ printErrorMessageTitle }}
                </v-card-title>

                <v-card-title v-if="printErrorMessage">
                  {{ printErrorMessage }}
                </v-card-title>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn color="red darken-1" text @click="canceldialogPopUp">
                    Cancel
                  </v-btn>

                  <v-btn color="green darken-1" text @click="savedialogPopUp">
                    OK
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </template>
        <template>
          <!-- OTP Dialog -->
          <v-dialog v-model="emailDialogVisible" max-width="600">
            <v-card>
              <v-card-title>
                <v-row>
                  <v-col>
                    <span class="headline font-weight-bold">
                      Quick Security Check
                    </span>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="text-center pb-0 mb-0">
                <h3>Enter your verification code</h3>
                <p>Use Email to generate a code and enter it below to sign in</p>
                <v-row justify="center">
                   <v-col cols="10">
                    <v-text-field
                      label="Email address to which login verification codes should be sent"
                      v-model="emailAddress"
                      outlined
                      dense
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" class="d-flex justify-end">
                    <v-btn color="primary" @click="sendEmailVerificationCode">
                      Submit
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="emailVerificationCode"
                      :rules="emailVerificationCodeRules"
                      label="Enter verification code"
                      outlined
                      dense
                      required
                      maxlength="6"
                      counter="6"
                    ></v-text-field>
                    <div class="italic-text mt-2">
                      For any queries, please contact
                      <a href="mailto:customer.support@didnumberprovider.com">
                        customer.support@didnumberprovider.com
                      </a>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn color="blue darken-1" text @click="canceldialogPopUp">
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary"                   
                  :disabled="!isEmailVerificationCodeValid"
                  @click="verifySecurityEmailAuthenticator">
                    Proceed
                </v-btn>
              </v-card-actions>
               <!-- Add the bottom message if the condition is true -->
              <div v-if="showBottomMessage" class="italic-text mt-2">
                <v-card-actions>
                  <v-divider></v-divider>
                  <v-btn color="blue darken-1" text @click="switchToOtpDialog">
                    Choose an Authenticator verification method
                  </v-btn>
                  <v-divider></v-divider>
                </v-card-actions>
              </div>
            </v-card>
          </v-dialog>
        </template>
        <template>
          <!-- OTP Dialog -->
          <v-dialog v-model="otpDialogVisible" max-width="600">
            <v-card>
              <v-card-title>
                <v-row>
                  <v-col>
                    <span class="headline font-weight-bold">
                      Quick Security Check
                    </span>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="text-center pb-0 mb-0">
                <h3>Enter your verification code</h3>
                <p>Use Authenticator app to generate a code and enter it below to sign in</p>
                <v-row justify="center">
                  <v-col cols="12">
                    <v-text-field
                      v-model="verificationCode"
                      :rules="verificationCodeRules"
                      label="Enter verification code"
                      outlined
                      dense
                      required
                      maxlength="6"
                      counter="6"
                    ></v-text-field>
                    <div class="italic-text mt-2">
                      For any queries, please contact
                      <a href="mailto:customer.support@didnumberprovider.com">
                        customer.support@didnumberprovider.com
                      </a>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn color="blue darken-1" text @click="canceldialogPopUp">
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary"                   
                  :disabled="!isVerificationCodeValid"
                  @click="verifySecurityGoogleAuthenticator">
                    Proceed
                </v-btn>
              </v-card-actions>
              <!-- Add the bottom message if the condition is true -->
              <div v-if="showBottomMessage" class="italic-text mt-2">
                <v-card-actions>
                  <v-divider></v-divider>
                  <v-btn color="blue darken-1" text @click="switchToEmailDialog">
                    Choose an email verification method
                  </v-btn>
                  <v-divider></v-divider>
                </v-card-actions>
              </div>
            </v-card>
          </v-dialog>
          </template>
        <template>
          <v-dialog v-model="locationChangedLog" max-width="600">
            <v-card>
              <v-card-title>
                <v-row>
                  <v-col>
                    <span class="headline font-weight-bold">Quick Security Check</span>
                  </v-col>
                  <v-col class="text-right">
                    <v-btn icon @click="canceldialogPopUp">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider></v-divider>

              <v-card-text class="pb-0 mb-0" style="font-weight: 500; margin-bottom: 16px; margin-top: 16px; text-align: center;">
                <label>Since you are logging from a Different IP , Please complete Security check. This helps us Protect your account.</label>
                <v-row>
                  <v-col>
                    <!-- Checkbox option -->
                      <v-checkbox
                        v-model="securityQuestionOption"
                        label="Answer your security questions"
                        :checked="true"
                        :disabled="true"
                        color="primary">
                      </v-checkbox>
                      <!-- Italic text -->
                      <div style="font-style: italic; text-align: center; margin-top: 8px;">
                        For any queries, please contact
                        <a href="mailto:customer.support@didnumberprovider.com" >customer.support@didnumberprovider.com</a>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="canceldialogPopUp"
                    class="bordered-btn">
                    Cancel
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="savedialogPopUp">
                    Proceed
                  </v-btn>
                </v-card-actions>
              </v-card>
          </v-dialog>
        </template>

        <v-col cols="12">
          <v-row class="pt-15" align="center" justify="center">
            <v-card class="form-card mt-10 pt-5" width="500">
              <v-col cols="12">
                <v-card-text>
                  <h1
                    class="header-font blue-grey--text text--darken-2 font-weight-bold pb-4"
                  >
                    Sign in
                  </h1>
                  <h6 class="subtitle-1 pb-4 font-weight-light">
                    Don't have an account?
                    <a href="/signup"> Sign Up</a>
                  </h6>
                  <v-form v-model="isFormValid" ref="loginForm" class="py-3">
                    <loading :active="isLoading" :loader="loader" />
                    <v-text-field
                      v-model.trim="loginData.username"
                      :rules="nameRules"
                      label="Email"
                      outlined
                      required
                    ></v-text-field>
                    <v-text-field
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.required]"
                      :type="showPassword ? 'text' : 'password'"
                      v-model.trim="loginData.password"
                      label="Password"
                      @keyup.enter="doLogin"
                      @click:append="showPassword = !showPassword"
                      outlined
                      hide-details
                    >
                    </v-text-field>
                    <v-divider></v-divider>
                    <v-card-actions class="px-0">
                      <!-- <v-checkbox
                        v-model="checkbox"
                        label="Remember me?"
                      ></v-checkbox> -->
                      <v-spacer></v-spacer>
                      <a href="/forgotPassword" class="link">Forgot pwd?</a>
                    </v-card-actions>
                    <v-card-actions class="pt-0 px-0">
                      <v-btn
                        color="primary"
                        class="px-0 mx-0 btn-loging"
                        block
                        large
                        @click="doLogin"
                        :disabled="!isFormValid"
                        >LOGIN
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card-text>
              </v-col>
            </v-card>
          </v-row>
        </v-col>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import authAPI from "@/services/authAPI.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import backgroundUrl from "../../assets/images/didpanel.jpg";
import axios from "axios";
import profileAPI from "@/services/profileAPI.js";
export default {
  data() {
    return {
      isLoading: false,
      //fullPage: false,
      loader: "bars",
      ipAdddress: "",
      countryCode: "",
      loginData: {
        username: "",
        password: ""
      },
      nameRules: [
        v =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "this value should be a valid email"
      ],
      rules: {
        required: value => !!value || "Password is Required."
      },
      resultChecking: "",
      e1: true,
      loading: false,
      disabled: true,
      isFormValid: false,
      showMessageSnackbar: false,
      showErrordialog: false,
      locationChangedLog: false,
      otpDialogVisible: false,
      emailDialogVisible: false,
      showMessage: "",
      dialog: false,
      printErrorMessage: "",
      printErrorMessageTitle: "",
      showPassword: false,
      showBottomMessage: false, // Add a flag for showing the bottom message
      checkbox: false,
      backgroundUrl: "",
      securityQuestionOption: true,
      verificationCode: "",
      showErrorTwoFactordDalog: false,
      emailVerificationCode: "",
      emailAddress: localStorage.getItem("username"),
      verificationCodeRules: [
        (v) => !!v || "Verification code is required.",
        (v) => /^[0-9]+$/.test(v) || "Verification code must be numeric.",
        (v) => v.length === 6 || "Verification code must be exactly 6 digits.",
      ],
      emailVerificationCodeRules: [
        (v) => !!v || "Verification code is required.",
        (v) => /^[0-9]+$/.test(v) || "Verification code must be numeric.",
        (v) => v.length === 6 || "Verification code must be exactly 6 digits.",
      ],
    };
  },
  computed: {
    isVerificationCodeValid() {
      return this.verificationCodeRules.every((rule) => rule(this.verificationCode) === true);
    },
    isEmailVerificationCodeValid() {
      return this.emailVerificationCodeRules.every((rule) => rule(this.emailVerificationCode) === true);
    },
  },
  components: {
    Loading
  },

  mounted() {
    this.backgroundUrl = backgroundUrl;
    // this.readDataFromAPI();
  },

  methods: {
    closeMessageDialog() {
      this.showMessageSnackbar = false;
      this.$refs.loginForm.reset();
    },

    // Switch dialogs from OTP to Email Verification
    switchToEmailDialog() {
      this.otpDialogVisible = false; // Hide OTP dialog
      this.emailDialogVisible = true; // Show Email dialog
      this.showBottomMessage = true; // Add a flag for showing the bottom message
    },

    // Switch dialogs from OTP to Email Verification
    switchToOtpDialog() {
      this.otpDialogVisible = true; // Hide OTP dialog
      this.emailDialogVisible = false; // Show Email dialog
      this.showBottomMessage = true; // Add a flag for showing the bottom message
    },

    canceldialogPopUp() {
      this.showErrordialog = false;
      this.locationChangedLog = false;
      this.otpDialogVisible = false;
      this.emailDialogVisible = false;
      this.showErrorTwoFactordDalog = false;
    },

    closeDialogPopUp() {
      this.showErrorTwoFactordDalog = false;
      //this.$router.push("/dashboard");
    },

  async verifySecurityGoogleAuthenticator() {
    this.isLoading = true;

    // Validate verification code before making the API call
    if (!this.verificationCode || this.verificationCode.trim() === "") {
      this.$root.$emit("SHOW_SNACKBAR", {
        text: "Verification code cannot be empty.",
        color: "error",
      });
      this.isLoading = false;
      return;
    }

    // Retrieve user data from local storage
    const userLoginId = localStorage.getItem("nameOfUser");
    this.userLoginId = window.atob(userLoginId);
    console.log("====321=====userLoginId========="+this.userLoginId);
    const password = localStorage.getItem("passwordOfUser");
    this.password = window.atob(password);
    const geoLocation = localStorage.getItem("countryCode") || "IN"; // Default to "IN" if not set

    const sendVerificationPayload = {
      verificationCode: this.verificationCode,
      password: this.password,
      geoLocation,
      username: this.userLoginId,
    };

    try {
      // Call API to verify the Google Authenticator code
      const res = await authAPI.verifySecurityGoogleAuthenticator(sendVerificationPayload);

      // Show success message
      this.$root.$emit("SHOW_SNACKBAR", {
        text: res.messageDetail || "Verification completed successfully!",
        color: "success",
      });

      // Save session details securely
      localStorage.setItem("authToken", res.partyDetail.authToken);
      localStorage.setItem("sessionId", res.sessionId);
      localStorage.setItem("userDetail", JSON.stringify(res.partyDetail));
      localStorage.setItem(
        "auth",
        btoa(`${window.atob(userLoginId)}:${window.atob(password)}`)
      );
      localStorage.setItem("saveAuth", password);

      // Redirect based on user status
      if (res.partyDetail.statusId === "PARTY_ENABLED") {
        this.$router.push("/dashboard");
      } else {
        this.showErrordialog = true;
        this.printErrorMessage = res.errorMessage || "User is not enabled.";
        location.href = "/";
      }
    } catch (error) {
      // Handle API errors
      let errorMessage = "Failed to verify the code. Please try again.";
      console.log("Data: ", JSON.stringify(error.data.messageDetail));
      if (error.data && error.data.messageDetail) {
        errorMessage =  JSON.stringify(error.data.messageDetail);
      }
      console.log("errorMessage: ", errorMessage);
        this.showErrorTwoFactordDalog = true;
        this.printErrorMessage = errorMessage;
        // location.href = "/";
    } finally {
      // Ensure loading indicator is turned off
      this.isLoading = false;
    }
  },
    // async readDataFromAPI() {
    //   try {
    //     this.loading = true;
    //     const response = await axios.get("https://api.country.is/");

    //     // Check if response contains valid data
    //     if (response && response.data) {

    //       // Update component state with retrieved data
    //       this.ipAdddress = response.data.ip;
    //       this.countryCode = response.data.country;
    //     } else {
    //       console.error("Invalid or incomplete response data:", response);
    //       // Set default values if response data is invalid
    //       this.ipAdddress = "192.167.1.4";
    //       this.countryCode = "IN";
    //     }

    //      // Store data in local storage
    //     localStorage.setItem("ipAddress", this.ipAdddress);
    //     localStorage.setItem("countryCode", this.countryCode);

    //     // Update component state from local storage
    //     this.ipAdddress = localStorage.getItem("ipAddress");
    //     this.countryCode = localStorage.getItem("countryCode");

    //     // Log IP address and country code
    //     console.log("Country Code:", this.countryCode, "IP Address:", this.ipAdddress);
    //   } catch (error) {
    //     console.error("Error fetching IP data:", error);

    //   } finally {
    //     // Ensure loading indicator is turned off
    //     this.loading = false;
    //   }
    // },

    async doLogin() {
      this.isLoading = true;
      const response = await axios.get("https://api.country.is/");
      // Check if response contains valid data
      if (response && response.data) {
        // Update component state with retrieved data
        this.ipAdddress = response.data.ip;
        this.countryCode = response.data.country;
      } else {
        console.error("Invalid or incomplete response data:", response);
        // Set default values if response data is invalid
        this.ipAdddress = "192.167.1.4";
        this.countryCode = "IN";
      }

      // Store data in local storage
      localStorage.setItem("ipAddress", this.ipAdddress);
      localStorage.setItem("countryCode", this.countryCode);

      // Update component state from local storage
      this.ipAdddress = localStorage.getItem("ipAddress");
      this.countryCode = localStorage.getItem("countryCode");

      this.loginData.geoLocation = this.countryCode;
      this.loginData.clientIpAddress = this.ipAdddress;

      // localStorage.setItem('nameOfUser', this.loginData.username)
      // localStorage.setItem('countryCode', this.loginData.geoLocation)
      // localStorage.setItem('passwordOfUser', this.loginData.password)

      try {
        localStorage.setItem("username", this.loginData.username);
        this.loginData.username = this.loginData.username.toLowerCase();
        var userName = localStorage.getItem("nameOfUser");
        // console.log("logindata",this.loginData)
        let res = await authAPI.login(this.loginData); // Login Api
        localStorage.setItem(
          "authForSecurityQues",
          btoa(`${this.loginData.username}:${this.loginData.password}`)
        );
        if (res.partyDetail.partyId) {
          //Checking Question Api
          let result = await authAPI.checkwithpartyId({
            partyId: res.partyDetail.partyId
          });
          // console.log("result", res.partyDetail.partyId);
          if (result && result.data && result.data.securityQuestions === null) {
            // console.log("partyid====>",res.partyDetail.partyId)
            //this.$router.push("/securityQuestion",res.partyDetail.partyId);
            this.$router.push({
              name: "SecurityQuestion",
              params: {
                partyId: res.partyDetail.partyId,
                email: this.loginData.username
              }
            });
          } else {
            if (res.partyDetail) {
              localStorage.setItem("authToken", res.partyDetail.authToken);
              localStorage.setItem("sessionId", res.sessionId);
              localStorage.setItem(
                "saveAuth",
                window.btoa(this.loginData.password)
              );
              localStorage.setItem(
                "userDetail",
                JSON.stringify(res.partyDetail)
              );
              localStorage.setItem(
                "auth",
                btoa(`${this.loginData.username}:${this.loginData.password}`)
              );
              this.$router.push("/profile");
              // this.text = res.responseMessage;
              setTimeout(() => {
                this.isLoading = false;
              }, 3000);
            } else {
              // Show message
              this.showMessage = res.successMessage;
              this.showMessageSnackbar = true;
            }
          }
        }
      } catch (err) {
        this.isLoading = false;
        if (err.data && err.data.messageDetail) {
          this.printErrorMessage = err.data.messageDetail;
          this.printErrorMessageTitle = err.data.responseMessage;
          if (err.data.messageDetail == "Location Changed") {
            this.locationChangedLog = true;
          } else if(err.data.messageDetail == "Google Authenticator Enabled"){
            this.otpDialogVisible = true;
          } else if(err.data.messageDetail == "Email Authenticator Enabled"){
            this.emailDialogVisible = true;
          } else if(err.data.messageDetail == "Email And Google Authenticator Enabled"){
            this.otpDialogVisible = true;
            this.showBottomMessage = true; // Add a flag for showing the bottom message
          } else {
            this.showErrordialog = true;
          }
        } else {
          this.showErrordialog = true;
          this.printErrorMessage = "Something wrong!";
        }
        if (this.printErrorMessage == "Location Changed" || this.printErrorMessage == "Google Authenticator Enabled" || this.printErrorMessage == "Email Authenticator Enabled" || this.printErrorMessage == "Email And Google Authenticator Enabled") {
          this.isLoading = false;
          this.emailAddress = this.loginData.username;
          localStorage.setItem(
            "nameOfUser",
            window.btoa(this.loginData.username)
          );
          localStorage.setItem(
            "passwordOfUser",
            window.btoa(this.loginData.password)
          );
        }
        // this.$root.$emit("SHOW_SNACKBAR", {
        //   text: "Incorrect Password",
        //   color: "error",
        // });
      }

      // --3rd--
      //   authAPI.login(this.loginData, (err, data) => {
      //  if (err !== null) {
      //  } else {
      // }
      //   });
    },
    async sendEmailVerificationCode() {
      this.isLoading = true;
      // Logic for submitting the email address
      console.log('Email submitted:', this.emailAddress);
       let sendEmailVerificationPayload = {
        username: this.emailAddress,
      };
      try {
        const response = await profileAPI.sendEmailVerificationCode(sendEmailVerificationPayload);
        let successMessage = "Two-Factor Email Authentication Code sent successfully.";
        console.log(response.data+" :Data: ", JSON.stringify(response));
        if (response.data && response.data.messageDetail) {
          successMessage =  JSON.stringify(response.data.messageDetail);
        }
        console.log("successMessage: ", successMessage);
        this.showErrorTwoFactordDalog = true;
        this.printErrorMessage = successMessage;
      } catch (error) {
        // Handle API errors
        let errorMessage = "Failed to send email. Please try again.";
        console.log("Data: ", JSON.stringify(error.data.messageDetail));
        if (error.data && error.data.messageDetail) {
          errorMessage =  JSON.stringify(error.data.messageDetail);
        }
        console.log("errorMessage: ", errorMessage);
          this.showErrorTwoFactordDalog = true;
          this.printErrorMessage = errorMessage;
          // location.href = "/";
      } finally {
        // Ensure loading indicator is turned off
        this.isLoading = false;
      }
    },

    async verifySecurityEmailAuthenticator() {
      this.isLoading = true;

      // Validate verification code before making the API call
      if (!this.emailVerificationCode || this.emailVerificationCode.trim() === "") {
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "Verification code cannot be empty.",
          color: "error",
        });
        this.isLoading = false;
        return;
      }
      const password = localStorage.getItem("passwordOfUser");
      this.password = window.atob(password);
      const geoLocation = localStorage.getItem("countryCode") || "IN"; // Default to "IN" if not set
      const userLoginId = localStorage.getItem("nameOfUser");
      this.userLoginId = window.atob(userLoginId);
      const sendEmailVerificationPayload = {
        verificationCode: this.emailVerificationCode,
        password: this.password,
        geoLocation,
        username: this.userLoginId,
      };

      try {
        // Call API to verify the Google Authenticator code
        const res = await authAPI.verifySecurityEmailAuthenticator(sendEmailVerificationPayload);

        // Show success message
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "Verification completed successfully!",
          color: "success",
        });

        // Save session details securely
        localStorage.setItem("authToken", res.partyDetail.authToken);
        localStorage.setItem("sessionId", res.sessionId);
        localStorage.setItem("userDetail", JSON.stringify(res.partyDetail));
        localStorage.setItem("auth",btoa(`${window.atob(userLoginId)}:${window.atob(password)}`));
        localStorage.setItem("saveAuth", password);

        // Redirect based on user status
        if (res.partyDetail.statusId === "PARTY_ENABLED") {
          this.$router.push("/dashboard");
        } else {
          this.showErrordialog = true;
          this.printErrorMessage = res.errorMessage || "User is not enabled.";
          location.href = "/";
        }
      } catch (error) {
        // Handle API errors
        let errorMessage = "Failed to verify the code. Please try again.";
        console.log("Data: ", JSON.stringify(error.data));
        if (error.data && error.data.messageDetail) {
          errorMessage =  JSON.stringify(error.data.messageDetail);
        }
        console.log("errorMessage: ", errorMessage);
          this.showErrorTwoFactordDalog = true;
          this.printErrorMessage = errorMessage;
          // location.href = "/";
      } finally {
        // Ensure loading indicator is turned off
        this.isLoading = false;
      }
  },

  async savedialogPopUp() {
    console.log("called api");
    if (this.printErrorMessage == "Location Changed") {
      // this.$router.push("/verifyUser");
      location.href = "/verifyUser";
    } else if(this.printErrorMessage == "Google Authenticator Enabled"){
      console.log("=========Google========Authenticator====");
    }else if(this.printErrorMessage == "Email Authenticator Enabled"){
      console.log("=========Email========Authenticator====");
    }else if(this.printErrorMessage == "Email And Google Authenticator Enabled"){
    console.log("=========Email==AND====GOOGLE==Authenticator====");
    }else {
      this.showErrordialog = false;
      this.locationChangedLog = false;
      this.otpDialogVisible = false;
      this.emailDialogVisible = false;
    }
  }
  }
};
</script>

<style scoped>
.btn-loging {
  margin-bottom: 15px;
}
</style>
